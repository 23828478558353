import {Component, OnInit} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {environment} from '../environments/environment';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  readonly tokenRegistrationUrl = environment.backendUrl + '/kontaflow/task/app/capture/notif/subscribe?token=';

  constructor(private afMessaging: AngularFireMessaging,
              private http: HttpClient) {

    this.afMessaging.messaging.subscribe(
      (messaging) => {
        messaging.onMessage = messaging.onMessage.bind(messaging);
        messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
      }
    );

  }


  ngOnInit(): void {
    this.requestPermission();
    // this.afMessaging.messages.subscribe(
    //   message => console.log('message:' + message)
    // );

  }

  requestPermission() {
    this.afMessaging.requestToken
      .subscribe(
        (token) => {
          console.log('this.afMessaging.requestToken: ' + token);
          // register token into Konta API

          console.log('Sending token to server...');


          this.http.post<any>(this.tokenRegistrationUrl + token, {})
            .subscribe(
              () => {
                // this.setTokenSentToServer(true);
                console.log('Posting token to API...');

              }
            );
        },
        (err) => {
          console.error('Unable to get permission to notify.', err);
        }
      );
  }


}

