import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {CaptureBacklog} from '../capture-list/capture-list.component';
import {shareReplay} from 'rxjs/operators';
import {InvoiceService} from './invoice.service';

@Injectable({providedIn: 'root'})
export class CaptureService {
  readonly CAPTURE_BACKLOG_URL = environment.backendUrl + '/invoicestore/dataops/api/capture/capture-backlog';
  selectedTenantIdentifier: string = null;
  currentTenantIdentifier: string = null;

  task$: Observable<any> = new Observable();

  private focusedInput = new BehaviorSubject<string>('');
  canvasChosen = new BehaviorSubject<boolean>(true);
  pageChanged = new BehaviorSubject<string>('first');
  isPdfSubject = new BehaviorSubject<boolean>(false);
  focusedInput$ = this.focusedInput.asObservable();
  subjectSelection = new Subject<any>();
  unclaimSubject = new Subject<any>();
  waitForUnclaim = false;
  captureBacklogSubject = new BehaviorSubject<CaptureBacklog[]>([]);

  constructor(private http: HttpClient, public invoiceService: InvoiceService) {

  }

  sendSelectedText(value: string) {
    this.subjectSelection.next({text: value});
  }

  changeInput(input: string) {
    this.focusedInput.next(input);
  }

  unclaimTask(val: string) {
    this.waitForUnclaim = true;
    this.unclaimSubject.next({text: val});
  }

  async fetchCaptureBacklog() {
    try {
      const data = await this.http.get<CaptureBacklog[]>(`${this.CAPTURE_BACKLOG_URL}`).toPromise();
      this.captureBacklogSubject.next(data);
    } catch (error) {
      console.error('Error fetching capture backlog:', error);
    }
  }

  getTask(taskId: string): void {
    this.task$ = this.invoiceService.getTaskByIdFromAPI(taskId).pipe(
      shareReplay(1)
    );
  }
}
