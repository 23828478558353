import { replace } from 'lodash';

const amountRegex = /(:|;|'|°|"|%|#|\s|\||\/|\\|[a-zA-Z]|\$|€|)/g;

// described in https://kontatech.atlassian.net/browse/KT-250
const referenceRegex = /(N°|n°|:|°|\||\[|\]|\(|\)|\s)/g;
const iceRegex = /(^-+|-+$)|(N°|n°|:|°|\||\[|\]|\(|\)|\s|\.)/g;

/**
 * this cleaner remove all characters that matches the amountRegex
 */
export function amountsCleaner(str: string): string {
  const regex = new RegExp(amountRegex);

  str = str.replace(/\s*-\s*/g, '-');

  let cleanedStr = replace(str, regex, '');

  const startsWithMinus = cleanedStr.startsWith('-');
  const endsWithMinus = cleanedStr.endsWith('-');

  // Remove any remaining minus signs to handle case where there might be multiple
  cleanedStr = cleanedStr.replace(/-/g, '');

  if (startsWithMinus || endsWithMinus) {
    return `-${cleanedStr}`;
  }

  return cleanedStr;
}

/**
 * Adapts amount strings to API e.g. replace ',' by '.'
 */
export function normalizeAmounts(str: string): string {
  return replace(str, ",", ".");
}

/**
 * this cleaner remove all characters that matches the referenceRegex
 */
export function referenceCleaner(str: string): string {
  const regex = new RegExp(referenceRegex);
  return replace(str, regex, "");
}

export function iceCleaner(str: string): string {
  const regex = new RegExp(iceRegex);
  return replace(str, regex, "");
}
