import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';

@Directive({
  selector: '[appDateInFuture]',
  providers: [{provide: NG_VALIDATORS, useExisting: DateInFutureDirective, multi: true}]
})
export class DateInFutureDirective implements Validator {

  constructor() {
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.patternValidator()(control);
  }

  patternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const d = new Date();
      const pastYear = d.getFullYear() - 5;
      d.setFullYear(pastYear);
      d.setDate(1);
      d.setMonth(0);

      if (control.value > new Date()) {
        return {dateInFuture: true};
      } else if (control.value < d) {
        return {fiveYearsBefore: true};
      } else {
        return null;
      }
    };
  }

}

